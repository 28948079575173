<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header :name="$translate('auth.user.profile')" previous="Go back to dashboard"
                        @on-previous="router.push({name:'dashboard'})">
                </Header>
            </div>
        </div>
        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-4 md:col-span-3">
                        <div class="bg-white shadow sm:rounded-lg overflow-hidden">
                            <MobileMenu :menu="navigation" />
                            <CardHeader :name="$translate('brands.title')" :description="$translate('brands.description')"/>
                            <DataTable :table="brands.table" :dataset="brands.list" empty="brands.empty">
                                <template #image>
                                    <img src="../../assets/images/empty.svg" class="h-32 m-auto mt-16 mb-8"/>
                                </template>
                                <template #empty>
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" @click.prevent="create"
                                            class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                                        {{ $translate('brands.create.title') }}
                                    </button>
                                </span>
                                </template>
                            </DataTable>
                            <CardFooter v-if="brands.list.length > 0">
                                <CardFooterButton :text="$translate('brands.create.title')" :primary="false" @click="create"/>
                            </CardFooter>
                            <CreateBrand v-model:visible="brands.create" @brand="loadBrands"/>
                            <UpdateBrand v-model:visible="brands.update" :brand="brands.edit" :name="brands.name" @updated="loadBrands" />
                            <Channels v-model:visible="brands.channels" :brand="brands.id"/>
                        </div>
                    </div>
                    <Submenu :menu="navigation" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseTable, DeleteAction, Pagination, TextColumn} from "@/types/table";
    import Channels from "@/pages/channels/Channels.vue";
    import CreateBrand from "@/pages/brands/CreateBrand.vue";
    import UpdateBrand from "@/pages/brands/UpdateBrand.vue";
    import {appStore, Brand} from "@/store/app";
    import {useTranslation} from "@/plugins/i18n";
    import {useConfirm} from "@/plugins/confirm";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {useNotification} from "@/plugins/notification";
    import {navigation} from "@/pages/profile/profile-menu";
    import router from "@/router";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";

    export default defineComponent({
        name: "Brands",
        components: {
            Channels,
            CreateBrand,
            UpdateBrand,
            Submenu,
            MobileMenu,
        },
        setup() {
            const i18n = useTranslation();
            const confirm = useConfirm();
            const axios = useAxios();
            const notification = useNotification();

            const brands = reactive({
                id: '',
                edit: '',
                name: '',
                update: false,
                create: false,
                channels: false,
                table: new BaseTable(Pagination.None),
                list: []
            })

            brands.table.addColumn(new TextColumn('name', 'brands.name'));

            brands.table.addAction(new BaseAction('table.action.edit')
                .setIcon('far fa-shopping-cart')
                .onClick((brand: Brand) => {
                    brands.id = brand.id;
                    brands.channels = true;
                }));

            brands.table.addAction(new BaseAction('table.action.edit')
                .onClick((brand: Brand) => {
                    brands.edit = brand.id;
                    brands.name = brand.name;
                    brands.update = true;
                }));

            brands.table.addAction(new DeleteAction('table.action.delete').onClick((brand: Brand) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                    brands.table.loading = true;
                    axios.delete(`brand-name/${brand.id}`).then(() => fetch()).catch(error => {
                        brands.table.loading = false;
                        notification.error(i18n.translate('brands.title'), getErrorMessage(error.response.data));
                    });
                });
            }));

            const create = () => {
                brands.create = true;
            }

            const fetch = () => {
                appStore.fetchBrands().then(() => {
                    brands.table.loading = false;
                    loadBrands();
                }).catch(() => {
                    brands.table.loading = false;
                    loadBrands();
                })
            }

            const loadBrands = () => {
                brands.list = [];
                appStore.getState().brands.forEach((brand: Brand) => {
                    // @ts-ignore
                    brands.list.push(brand);
                });
            }
            loadBrands();
            return {
                brands,
                create,
                loadBrands,
                navigation,
                router,
            }
        }
    })
</script>