
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseTable, DeleteAction, Pagination, TextColumn} from "@/types/table";
    import Channels from "@/pages/channels/Channels.vue";
    import CreateBrand from "@/pages/brands/CreateBrand.vue";
    import UpdateBrand from "@/pages/brands/UpdateBrand.vue";
    import {appStore, Brand} from "@/store/app";
    import {useTranslation} from "@/plugins/i18n";
    import {useConfirm} from "@/plugins/confirm";
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {useNotification} from "@/plugins/notification";
    import {navigation} from "@/pages/profile/profile-menu";
    import router from "@/router";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";

    export default defineComponent({
        name: "Brands",
        components: {
            Channels,
            CreateBrand,
            UpdateBrand,
            Submenu,
            MobileMenu,
        },
        setup() {
            const i18n = useTranslation();
            const confirm = useConfirm();
            const axios = useAxios();
            const notification = useNotification();

            const brands = reactive({
                id: '',
                edit: '',
                name: '',
                update: false,
                create: false,
                channels: false,
                table: new BaseTable(Pagination.None),
                list: []
            })

            brands.table.addColumn(new TextColumn('name', 'brands.name'));

            brands.table.addAction(new BaseAction('table.action.edit')
                .setIcon('far fa-shopping-cart')
                .onClick((brand: Brand) => {
                    brands.id = brand.id;
                    brands.channels = true;
                }));

            brands.table.addAction(new BaseAction('table.action.edit')
                .onClick((brand: Brand) => {
                    brands.edit = brand.id;
                    brands.name = brand.name;
                    brands.update = true;
                }));

            brands.table.addAction(new DeleteAction('table.action.delete').onClick((brand: Brand) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                    brands.table.loading = true;
                    axios.delete(`brand-name/${brand.id}`).then(() => fetch()).catch(error => {
                        brands.table.loading = false;
                        notification.error(i18n.translate('brands.title'), getErrorMessage(error.response.data));
                    });
                });
            }));

            const create = () => {
                brands.create = true;
            }

            const fetch = () => {
                appStore.fetchBrands().then(() => {
                    brands.table.loading = false;
                    loadBrands();
                }).catch(() => {
                    brands.table.loading = false;
                    loadBrands();
                })
            }

            const loadBrands = () => {
                brands.list = [];
                appStore.getState().brands.forEach((brand: Brand) => {
                    // @ts-ignore
                    brands.list.push(brand);
                });
            }
            loadBrands();
            return {
                brands,
                create,
                loadBrands,
                navigation,
                router,
            }
        }
    })
