<template>

    <div class="hidden md:block">
        <div class="pt-24 mt-2 pl-4 sm:pl-6 lg:pl-8">
            <nav>
                <a v-for="(item, index) in menu" :key="`nav_item_${index}`"
                   @click.prevent="router.push({name: item.name})" href="#"
                   :class="{
                                       'group flex items-center px-3 py-2 text-sm leading-5 transition rounded-md  ease-in-out duration-150': true,
                                       'mt-1': index > 0,
                                       'font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50' : router.currentRoute.value.name !== item.name,
                                       'font-medium text-gray-900 bg-gray-100 hover:text-gray-900 hover:bg-gray-100 focus:outline-none':router.currentRoute.value.name === item.name }">
                    <span class="truncate">{{ $translate(item.caption) }}</span>
                </a>
            </nav>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from 'vue';
    import router from "@/router";
    import {IRoute} from "@/pages/profile/profile-menu";

    export default defineComponent({
        name: "Submenu",
        props: {
            menu: {
                type: Object as PropType<IRoute>,
                required: true,
            }
        },
        setup() {
            return {
                router
            }
        }
    })
</script>