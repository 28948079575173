<template>
    <Drawer :drawer="createBrandDrawer" :title="$translate('brands.create.title')"
            :description="$translate('brands.create.description')" width="max-w-3xl">
        <div class="px-4 py-5 sm:p-6">
            <Loading :loading="general.loading"/>
            <DataForm v-if="!general.loading" :form="form" v-model="values"/>
        </div>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {getErrorMessage, useAxios} from "@/plugins/axios";
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {TextField} from "@/types/field";
    import {MinLengthValidationRule, RequiredValidationRule} from "@/types/validation";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import {appStore} from "@/store/app";

    export default defineComponent({
        name: "CreateBrand",
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        emits: ['brand', 'update', 'update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();
            const notification = useNotification();
            const i18n = useTranslation();

            const general = reactive({loading: false});

            const form = reactive(new BaseForm());
            form.addField(new TextField('brand', 'brands.name')
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(2))
            )
            const values = reactive({
                brand: '',
            });

            //  Drawer
            const createBrandDrawer = reactive(new usePromise());
            const createBrand = async () => {
                const confirmed = await createBrandDrawer.show();
                if (confirmed) {
                    form.setError('').submit().then(() => {
                        axios.post('brand-name', {name: values.brand})
                            .then(response => {
                                appStore.fetchBrands().then(() => {
                                    //  Show success, emit and close
                                    emit('brand');
                                    emit('update:visible', false);
                                    createBrandDrawer.saving = false;
                                    notification.success(
                                        i18n.translate('brands.created.title'),
                                        i18n.translate('brands.created.description')
                                    );
                                    createBrandDrawer.hide();
                                })
                                    .catch(error => {
                                        createBrandDrawer.saving = false;
                                        form.setError(getErrorMessage(error.response.data));
                                        createBrand();
                                    })
                            })
                            .catch(error => {
                                createBrandDrawer.saving = false;
                                form.setError(getErrorMessage(error.response.data));
                                createBrand();
                            })
                    }).catch(() => {
                        createBrandDrawer.saving = false;
                        createBrand();
                    })
                } else {
                    createBrandDrawer.saving = false;
                    emit('update:visible', false);
                }
            }
            watch(() => props.visible, (visible) => {
                if (visible) {
                    createBrand();
                } else {
                    setTimeout(() => {
                        values.brand = '';
                    }, 500);
                }
            }, {deep: true, immediate: true})

            return {
                general,
                createBrandDrawer,
                form,
                values,
            }
        }
    })
</script>