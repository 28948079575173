
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {SelectField, TextField} from "@/types/field";
    import {MinLengthValidationRule, RequiredValidationRule} from "@/types/validation";
    import {FieldOption} from "@/types/option";
    import {useAxios, getErrorMessage} from "@/plugins/axios";
    import {Logic, LogicMatch, LogicMethod, LogicRule, LogicRuleOperator} from "@/types/logic";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";

    export default defineComponent({
        name: "CreateChannel",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            brand: {
                type: String,
                default: '',
            }
        },
        unmounted() {
            this.resetForm();
        },
        emits: ['channel', 'update', 'update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();
            const general = reactive({loading: false});

            //  Form
            const channel = reactive(new SelectField('channel', 'campaign.connection.channel'));
            channel.addOption(new FieldOption('1', '1')).setWidth(12);
            const form = reactive(new BaseForm());
            const resetForm = () => {
                form.removeFields();
                form.addField(channel);
            }
            resetForm();

            let marketplaces = reactive([]);
            const values = reactive({
                channel: '',
            });

            //  Fetch e-commerce channels
            const fetch = () => {
                general.loading = true;
                axios.get('channels/all').then(response => response.data).then(response => {
                    resetForm();
                    marketplaces = response;
                    general.loading = false;
                    channel.removeOptions();
                    response.forEach((marketplace: any, index: number) => {
                        const channelKey = index.toString();
                        channel.addOption(new FieldOption(channelKey, marketplace.name).preventTranslate());
                        marketplace.form_fields.forEach((field: any) => {
                            form.addField(
                                new TextField(field.id, field.name)
                                    .setLogic(new Logic()
                                        .setMatch(LogicMatch.Any)
                                        .setOperator(LogicMethod.Show)
                                        .addRule(new LogicRule('channel', LogicRuleOperator.Is, channelKey))
                                    )
                                    .preventTranslate()
                                    .addRule(new RequiredValidationRule()));
                        });
                        if (index === 0) {
                            values.channel = channelKey;
                        }

                    });
                }).catch(() => {
                    general.loading = false;
                });
            }

            const i18n = useTranslation();
            const notification = useNotification();

            //  Drawer
            const createConnectionDrawer = reactive(new usePromise());
            const createConnection = async () => {
                const confirmed = await createConnectionDrawer.show();
                if(confirmed) {

                    form.setError('').submit().then(() => {
                        const marketplace = marketplaces[parseInt(values.channel)];
                        const credentials = reactive({});

                        // @ts-ignore
                        marketplace.form_fields.forEach((field: object) => {
                            // @ts-ignore
                            credentials[field.id] = values[field.id];
                        });

                        //  @ts-ignore
                        axios.post('channel', {mis_id: marketplace.mis_id, credentials, brand_name_id: props.brand})
                            .then(response => response.data)
                            .then(response => {
                                //  Show success, emit and close
                                emit('channel');
                                emit('update:visible', false);
                                createConnectionDrawer.saving = false;
                                notification.success(
                                    i18n.translate('campaign.connection.added.title'),
                                    i18n.translate('campaign.connection.added.description')
                                );
                                createConnectionDrawer.hide();
                            })
                            .catch((error) => {
                                createConnectionDrawer.saving = false;
                                form.setError(getErrorMessage(error.response.data));
                                createConnection();
                            });
                        // createConnection();
                    }).catch(() => {
                        createConnectionDrawer.saving = false;
                        createConnection();
                    })
                }
                else {
                    createConnectionDrawer.saving = false;
                    emit('update:visible', false);
                }
            }
            watch(() => props.visible, (visible) => {
                if (visible) {
                    fetch();
                    createConnection();
                }
            }, {deep: true, immediate: true})

            return {
                general,
                resetForm,
                form,
                values,
                createConnectionDrawer,
                createConnection
            }
        }
    })
