export interface Option {
    value: any,
    caption: string,
    disabled: boolean,
    translate: boolean,
}

export class FieldOption implements Option {
    caption: string = '';
    value: any = '';
    disabled: boolean = false;
    translate: boolean = true;

    constructor(value: any, caption: string) {
        this.value = value;
        this.caption = caption;
    }

    public preventTranslate(): FieldOption {
        this.translate = false;
        return this;
    }

    public setDisabled(): FieldOption {
        this.disabled = true;
        return this;
    }

    public setEnabled(): FieldOption {
        this.disabled = false;
        return this;
    }
}
