
    import {defineComponent, PropType} from 'vue';
    import {IRoute} from "@/pages/profile/profile-menu";
    import router from "@/router";

    export default defineComponent({
        name: "MobileMenu",
        props: {
            menu: {
                type: Object as PropType<IRoute>,
                required: true,
            }
        },
        setup() {
            const gotoPage = (event: any) => {
                router.push({name: event.target.value});
            }
            return {
                router,
                gotoPage,
            }
        }
    })
