
    import {defineComponent, reactive, watch} from 'vue';
    import {useConfirm} from "@/plugins/confirm";
    import {useTranslation} from "@/plugins/i18n";
    import CreateChannel from "@/pages/channels/CreateChannel.vue";
    import {useAxios} from "@/plugins/axios";
    import {BaseAction, BaseColumn, BaseTable, DeleteAction, Pagination} from "@/types/table";
    import {Channel} from "@/interfaces/campaign";
    import {usePromise} from "@/components/usePromise";


    export default defineComponent({
        name: "Channels",
        components: {
            CreateChannel
        },
        props: {
            brand: {
                type: String,
                default: ''
            },
            visible: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update', 'update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();
            const i18n = useTranslation();
            const confirm = useConfirm();

            const channels = reactive({
                table: reactive(new BaseTable(Pagination.None)),
                loading: true,
                editing: 0,
                list: [],
            });

            channels.table.addColumn(new BaseColumn('name', 'channels.channel'));
            channels.table.addColumn(new BaseColumn('reference', 'channels.reference').hideMobile());

            // channels.table.addAction(new BaseAction('table.action.edit').onClick((channel: ChannelRecord) => {
            //     alert('Edit channel');
            // }))

            channels.table.addAction(new DeleteAction('table.action.delete').onClick((channel: Channel) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                    channels.table.loading = true;
                    axios.delete(`channels/${channel.id}`).then(() => fetch(props.brand)).catch(() => fetch(props.brand))
                })
            }))

            const fetchCurrent = () => {
                fetch(props.brand);
            }
            const fetch = (brand: string) => {
                channels.table.loading = true;
                channels.list = [];
                axios.get(`channels/my?brand_name_id=${brand}`)
                    .then(response => response.data)
                    .then(response => {
                        channels.table.loading = false;
                        channels.list = response.channels;
                    });
            }
            watch(() => props.brand, (id: string) => id !== '' ? fetch(id): null, {immediate: true});

            const createChannel = reactive({show: false});
            const create = () => {
                createChannel.show = true;
            }

            const remove = (id: number) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                })
            }
            const showChannelsDrawer = reactive(new usePromise());
            const showChannels = async () => {
                const confirmed = await showChannelsDrawer.show();
                if(confirmed) {
                    await showChannels();
                } else {
                    showChannelsDrawer.saving = false;
                    emit('update:visible', false);
                }
            };

            watch(() => props.visible, (visible) => {
                if (visible) {
                    showChannels();
                }
            }, {deep: true, immediate: true})

            return {
                fetchCurrent,
                createChannel,
                create,
                channels,
                remove,
                showChannelsDrawer,
            }
        }
    })
