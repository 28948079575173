
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {BaseForm} from "@/types/form";
    import {TextField} from "@/types/field";
    import {MinLengthValidationRule, RequiredValidationRule} from "@/types/validation";
    import {appStore} from "@/store/app";
    import {getErrorMessage, useAxios} from "@/plugins/axios";

    export default defineComponent({
        name: "UpdateBrand",
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            brand: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            id: {
                type: String,
                default: '',
            }
        },
        emits: ['update', 'update:visible', 'updated'],
        setup(props, {emit}) {

            const axios = useAxios();

            const state = reactive({
                loading: false
            })

            const form = reactive(new BaseForm());
            form.addField(new TextField('brand', 'brands.name')
                .addRule(new RequiredValidationRule())
                .addRule(new MinLengthValidationRule(2)));

            const values = reactive({
                brand: '',
            });

            watch(() => props.name, (name: string) => {
                values.brand = name;
            }, {immediate: true});

            const updateBrandDrawer = reactive(new usePromise());
            const updateBrand = async () => {
                const confirmed = await updateBrandDrawer.show();
                if (confirmed) {
                    form.setError('').submit().then(() => {
                        axios.put(`brand-name/${props.brand}`, {name: values.brand})
                            .then(response => {
                                appStore.fetchBrands().then(() => {
                                    emit('updated');
                                    emit('update:visible', false);
                                    updateBrandDrawer.saving = false;
                                    updateBrandDrawer.hide();
                                })
                                    .catch(error => {
                                        updateBrandDrawer.saving = false;
                                        form.setError(getErrorMessage(error.response.data));
                                        updateBrand();
                                    })
                            })
                            .catch(error => {
                                updateBrandDrawer.saving = false;
                                form.setError(getErrorMessage(error.response.data));
                                updateBrand();
                            })
                    }).catch(() => {
                        updateBrandDrawer.saving = false;
                        updateBrand();
                    })
                } else {
                    updateBrandDrawer.saving = false;
                    emit('update:visible', false);
                }
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    updateBrand()
                }
            })

            return {
                state,
                updateBrandDrawer,
                form,
                values,
            }
        }
    })
