export interface IRoute {
    name: string,
    caption: string,
}

const navigation: IRoute[] = [{
    name: 'profile',
    caption: 'company.title'
}, {
    name: 'brands',
    caption: 'brands.title'
}, {
    name: 'social',
    caption: 'social.title'
}
// , {
//     name: 'referrals',
//     caption: 'pages.referrals'
// }
];

export {navigation}