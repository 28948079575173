<template>
    <Drawer :drawer="showChannelsDrawer" :title="$translate('channels.title')"
            :description="$translate('channels.description')" width="max-w-3xl">
            <DataTable :table="channels.table" :dataset="channels.list" empty="channels.no-results">
                <template #image>
                    <img src="../../assets/images/empty.svg" class="h-32 m-auto mt-16 mb-8"/>
                </template>
                <template #empty>
            <span class="inline-flex rounded-md shadow-sm">
                <button type="button" @click.prevent="create"
                        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-custom-300 focus:shadow-outline-custom active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                    {{ $translate('channels.create.button') }}
                </button>
            </span>
                </template>
            </DataTable>
            <CreateChannel v-model:visible="createChannel.show" :brand="brand" @channel="fetchCurrent"/>
        <template #footer>
            <div class="flex flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6" v-if="channels.list.length > 0">
                <div class="flex-grow flex items-center"></div>
                <div class="flex flex-row-reverse">
                    <div class="space-x-3 flex justify-end">
                        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button type="button"
                                    @click="create"
                                    :class="`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-600 hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500`">
                                {{ $translate(`channels.create.button`) }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {useConfirm} from "@/plugins/confirm";
    import {useTranslation} from "@/plugins/i18n";
    import CreateChannel from "@/pages/channels/CreateChannel.vue";
    import {useAxios} from "@/plugins/axios";
    import {BaseAction, BaseColumn, BaseTable, DeleteAction, Pagination} from "@/types/table";
    import {Channel} from "@/interfaces/campaign";
    import {usePromise} from "@/components/usePromise";


    export default defineComponent({
        name: "Channels",
        components: {
            CreateChannel
        },
        props: {
            brand: {
                type: String,
                default: ''
            },
            visible: {
                type: Boolean,
                default: false,
            }
        },
        emits: ['update', 'update:visible'],
        setup(props, {emit}) {

            const axios = useAxios();
            const i18n = useTranslation();
            const confirm = useConfirm();

            const channels = reactive({
                table: reactive(new BaseTable(Pagination.None)),
                loading: true,
                editing: 0,
                list: [],
            });

            channels.table.addColumn(new BaseColumn('name', 'channels.channel'));
            channels.table.addColumn(new BaseColumn('reference', 'channels.reference').hideMobile());

            // channels.table.addAction(new BaseAction('table.action.edit').onClick((channel: ChannelRecord) => {
            //     alert('Edit channel');
            // }))

            channels.table.addAction(new DeleteAction('table.action.delete').onClick((channel: Channel) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                    channels.table.loading = true;
                    axios.delete(`channels/${channel.id}`).then(() => fetch(props.brand)).catch(() => fetch(props.brand))
                })
            }))

            const fetchCurrent = () => {
                fetch(props.brand);
            }
            const fetch = (brand: string) => {
                channels.table.loading = true;
                channels.list = [];
                axios.get(`channels/my?brand_name_id=${brand}`)
                    .then(response => response.data)
                    .then(response => {
                        channels.table.loading = false;
                        channels.list = response.channels;
                    });
            }
            watch(() => props.brand, (id: string) => id !== '' ? fetch(id): null, {immediate: true});

            const createChannel = reactive({show: false});
            const create = () => {
                createChannel.show = true;
            }

            const remove = (id: number) => {
                confirm.delete(i18n.translate('form.delete.title'), i18n.translate('form.delete.description'), 'form.delete.confirm', 'form.no').then(() => {
                })
            }
            const showChannelsDrawer = reactive(new usePromise());
            const showChannels = async () => {
                const confirmed = await showChannelsDrawer.show();
                if(confirmed) {
                    await showChannels();
                } else {
                    showChannelsDrawer.saving = false;
                    emit('update:visible', false);
                }
            };

            watch(() => props.visible, (visible) => {
                if (visible) {
                    showChannels();
                }
            }, {deep: true, immediate: true})

            return {
                fetchCurrent,
                createChannel,
                create,
                channels,
                remove,
                showChannelsDrawer,
            }
        }
    })
</script>