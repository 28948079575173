<template>

    <div class="px-4 py-5 sm:p-6 pb-0 sm:pb-0 md:hidden">
        <select aria-label="Selected tab" class="form-select block w-full" @change="gotoPage">
            <option v-for="(item, index) in menu" :key="`nav_option_${index}`"
                    :value="item.name" :selected="router.currentRoute.value.name === item.name">
                {{ $translate(item.caption) }}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
    import {defineComponent, PropType} from 'vue';
    import {IRoute} from "@/pages/profile/profile-menu";
    import router from "@/router";

    export default defineComponent({
        name: "MobileMenu",
        props: {
            menu: {
                type: Object as PropType<IRoute>,
                required: true,
            }
        },
        setup() {
            const gotoPage = (event: any) => {
                router.push({name: event.target.value});
            }
            return {
                router,
                gotoPage,
            }
        }
    })
</script>